@font-face {
	font-family: 'Poppins';
	src: url('../public/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 1.5;
	/* background-image: url("../public/assets/images/food_icons_transparent_too_light_grey.png"); */
	background-size: 50%;
	color: #333;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}